<template>
  <v-main class="hero">
    <v-container>
      <component :is="currentHomePage"></component>
    </v-container>
  </v-main>
</template>

<script>
import { mapGetters } from 'vuex'
import Dashboard from '../Dashboard'
import PublicHomePage from './Public.vue'
import CustomerHomePage from './Customer.vue'

export default {
  name: 'Doricmor',
  components: {
    Dashboard,
    PublicHomePage,
    CustomerHomePage
  },
  computed: {
    ...mapGetters([
      'isAdmin',
      'isCustomer',
      'isCustomArtist',
      'isPublishedArtist']),
      currentHomePage: function () {
        if (this.isAdmin) {
          return Dashboard
        } else if (this.isPublishedArtist) {
          return Dashboard
        } else if (this.isCustomArtist) {
          return Dashboard
        } else if (this.isCustomer) {
          return CustomerHomePage
        } else if (this.isPublishedArtist) {
          return Dashboard
        } else if (this.isCustomArtist) {
          return Dashboard
        } else {
          return PublicHomePage
        }
      }
  },
}
</script>

<style scoped lang="scss">
  .v-card__actions {
    border-top: 1px solid silver;
  }
  .hero {
    background: url('../../assets/bg-014-green.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .hero::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(255, 255, 255, 0.25);
  }
</style>
