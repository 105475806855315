<template>
  <v-container text-xs-center>
    <userDebug></userDebug>
    <v-row>
      <v-col cols="12">
        <NewsCard></NewsCard>
      </v-col>
      <v-col md="6" sm="12">
        <SavedFilters></SavedFilters>
      </v-col>
      <v-col md="6" sm="12">
        <QuickProductLinks></QuickProductLinks>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SavedFilters from "@/components/widgets/SavedFilters";
import ArtistPromo from "@/components/widgets/ArtistPromo";
import InfoCard from "@/components/widgets/InfoCard";
import NewsCard from "@/components/widgets/NewsCard";
import QuickProductLinks from "@/components/widgets/QuickProductLinks";

export default {
  name: "Doricmor",
  components: {
    SavedFilters,
    InfoCard,
    QuickProductLinks,
    ArtistPromo,
    NewsCard
  },
  data: () => ({
  }),
  async created() {
  },
  methods: {
  },
  computed: {
  },
};
</script>
